/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";
import MKBox from "components/MKBox";
import Grid from "@mui/material/Grid";
// import View from "layouts/sections/components/View";

import qualifly1 from "assets/images/projects/qualifly1.png";
import qualifly2 from "assets/images/projects/qualifly2.png";
import qualifly3 from "assets/images/projects/qualifly3.png";
import qualifly4 from "assets/images/projects/qualifly4.png";
import qualifly5 from "assets/images/projects/qualifly5.png";
import qualifly6 from "assets/images/projects/qualifly6.png";

// PageHeaders page components
// import HeaderOne from "layouts/sections/page-sections/page-headers/components/HeaderOne";

// PageHeaders page components code
// import headerOneCode from "layouts/sections/page-sections/page-headers/components/HeaderOne/code";

function PageHeaders() {
  return (
    <BaseLayout
      title="Qualifly"
      subTitle="Education Platform for Primary School"
      breadcrumb={[
        { label: "Project" },
        { label: "Web Development" },
        { label: "Qualifly" },
      ]}
    >
      {/* <View> */}
      <Grid marginTop={{ xs: -3, lg: -3 }} />
      <Grid container item xs={12} lg={9} justifyContent="center" mx="auto">
        <MKBox
          sx={{ mt: 6 }}
          component="img"
          src={qualifly1}
          borderRadius="lg"
          shadow="md"
          width="100%"
          position="relative"
          zIndex={1}
        />
      </Grid>
      <Grid marginTop={{ xs: 3, lg: 6 }} />
      <Grid container item xs={12} lg={9} justifyContent="center" mx="auto">
        <MKBox
          component="img"
          src={qualifly2}
          borderRadius="lg"
          shadow="md"
          width="100%"
          position="relative"
          zIndex={1}
        />
      </Grid>
      <Grid marginTop={{ xs: 3, lg: 6 }} />
      <Grid container item xs={12} lg={9} justifyContent="center" mx="auto">
        <MKBox
          component="img"
          src={qualifly3}
          borderRadius="lg"
          shadow="md"
          width="100%"
          position="relative"
          zIndex={1}
        />
      </Grid>
      <Grid marginTop={{ xs: 3, lg: 6 }} />
      <Grid container item xs={12} lg={9} justifyContent="center" mx="auto">
        <MKBox
          component="img"
          src={qualifly4}
          borderRadius="lg"
          shadow="md"
          width="100%"
          position="relative"
          zIndex={1}
        />
      </Grid>
      <Grid marginTop={{ xs: 3, lg: 6 }} />
      <Grid container item xs={12} lg={9} justifyContent="center" mx="auto">
        <MKBox
          component="img"
          src={qualifly5}
          borderRadius="lg"
          shadow="md"
          width="100%"
          position="relative"
          zIndex={1}
        />
      </Grid>
      <Grid marginTop={{ xs: 3, lg: 6 }} />
      <Grid container item xs={12} lg={9} justifyContent="center" mx="auto">
        <MKBox
          component="img"
          src={qualifly6}
          borderRadius="lg"
          shadow="md"
          width="100%"
          position="relative"
          zIndex={1}
        />
      </Grid>
      {/* </View> */}
    </BaseLayout>
  );
}

export default PageHeaders;
