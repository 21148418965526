/*
=========================================================
* Material Kit 2 React - v2.1.0
========================================================= 

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function BuiltByDevelopers() {
  const bgImage =
    "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/desktop.jpg";

  return (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={2}
      py={6}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.8),
            rgba(gradients.dark.state, 0.8)
          )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Grid container item xs={12} lg={6} sx={{ ml: { xs: 0, lg: 6 } }}>
          <MKTypography variant="h3" color="white" fontWeight="bold"
            mb={1}
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("lg")]: {
                fontSize: size["2xl"],
              },
              [breakpoints.down("md")]: {
                fontSize: size["2xl"],
              },
            })}
          >
            Built with A.I.
          </MKTypography>
          <MKTypography variant="h2" color="white" mb={1}
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("lg")]: {
                fontSize: size["3xl"],
              },
              [breakpoints.down("md")]: {
                fontSize: size["3xl"],
              },
            })}
          >
            Transformation with the Power of Technology
          </MKTypography>
          <MKTypography variant="body2" color="white" opacity={0.8} mb={2}
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("lg")]: {
                fontSize: size["md"],
              },
              [breakpoints.down("md")]: {
                fontSize: size["md"],
              },
            })}
          >
            With the use of A.I., varies solution can be applied with low cost and high quality,
            which provides the best experience to our end users. It is the right time to transform
            traditional industry with the power of technology.
          </MKTypography>
          {/*
          <MKTypography
            component="a"
            href="https://www.creative-tim.com/learning-lab/react/overview/material-kit/"
            target="_blank"
            rel="noreferrer"
            variant="body2"
            color="white"
            fontWeight="regular"
            sx={{
              display: "flex",
              alignItems: "center",

              "& .material-icons-round": {
                fontSize: "1.125rem",
                transform: `translateX(3px)`,
                transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
              },

              "&:hover .material-icons-round, &:focus .material-icons-round": {
                transform: `translateX(6px)`,
              },
            }}
          >
            Read docs <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography> 
          */}
        </Grid>
      </Container>
    </MKBox >
  );
}

export default BuiltByDevelopers;
