/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpg";
import bgBack from "assets/images/rotating-card-bg-back.jpg";
import dopaperLogo from "assets/images/dopaper-icon.jpg";

function Information({ link }) {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={0} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                color="techcooSecondary"
                image={bgFront}
                iconImg={dopaperLogo}
                title={
                  <>
                    DoPaper
                  </>
                }
                description="To help you to pass any exam with messive practice paper and exercise, with excellent user experience."
              />
              <RotatingCardBack
                color="techcooSecondary"
                image={bgBack}
                title="Discover More"
                description="DoPaper is the ultimate app for mastering exams and achieving top scores. With an extensive library of practice questions covering a wide range of subjects and exam topics."
                action={{
                  type: "internal",
                  route: link === "more" ? "./dopaper/about" : "https://apps.apple.com/hk/app/dopaper/id6504157589",
                  label: link === "more" ? "More..." : "Download Now",
                }}
              />
            </RotatingCard>
            <div style={{ height: 30 }} />
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="Comprehensive Database"
                  description="Develop and built by A.I. Check out our App and you will find everything you need."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="flip_to_front"
                  title="User Friendly"
                  description="Focus on user experience and user interface for a seamless study experience."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="price_change"
                  title="Save Time & Money"
                  description="Offer free trial practice with high quality standard. Affordable pricing for premium services."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  title="Large Coverage"
                  description="A huge vacariety of exam choice which fit your need to prepare for your examination."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;

Information.propTypes = {
  link: PropTypes.string
}
