/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// const imagesPrefix =
//   "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/sections";

import qualifly1 from "assets/images/projects/qualifly1.png";
import menying1 from "assets/images/projects/menying1.png";
import storagehub1 from "assets/images/projects/storagehub1.png";
import moodfit1 from "assets/images/projects/moodfit1.png";
import daydayfit1 from "assets/images/projects/daydayfit1.png";
import vivablee1 from "assets/images/projects/vivablee1.png";

export default [
  {
    title: "Web Development",
    description: "Web-based solution to to attract customers and boost sales.",
    items: [
      {
        image: qualifly1,
        name: "Qualifly",
        description: "Education Platform for Primary School",
        route: "/project/qualifly",
      },
      {
        image: menying1,
        name: "Men Ying",
        description: "E-commerce Stationary Store",
        route: "/project/menying",
      },
      {
        image: storagehub1,
        name: "Storage Hub",
        description: "E-commerce Storage Platform",
        route: "/project/storagehub",
      },
      // {
      //   image: `${imagesPrefix}/faq.jpg`,
      //   name: "FAQ",
      //   count: 1,
      //   pro: true,
      // },
      // {
      //   image: `${imagesPrefix}/blogs.jpg`,
      //   name: "Blog Posts",
      //   count: 11,
      //   pro: true,
      // },
      // {
      //   image: `${imagesPrefix}/testimonials.jpg`,
      //   name: "Testimonials",
      //   count: 11,
      //   pro: true,
      // },
      // {
      //   image: `${imagesPrefix}/teams.jpg`,
      //   name: "Teams",
      //   count: 6,
      //   pro: true,
      // },
      // {
      //   image: `${imagesPrefix}/stats.jpg`,
      //   name: "Stats",
      //   count: 3,
      //   pro: true,
      // },
      // {
      //   image: `${imagesPrefix}/call-to-action.jpg`,
      //   name: "Call to Actions",
      //   count: 8,
      //   pro: true,
      // },
      // {
      //   image: `${imagesPrefix}/projects.jpg`,
      //   name: "Applications",
      //   count: 6,
      //   pro: true,
      // },
      // {
      //   image: `${imagesPrefix}/logo-area.jpg`,
      //   name: "Logo Areas",
      //   count: 4,
      //   pro: true,
      // },
      // {
      //   image: `${imagesPrefix}/footers.jpg`,
      //   name: "Footers",
      //   count: 10,
      //   pro: true,
      // },
      // {
      //   image: `${imagesPrefix}/general-cards.jpg`,
      //   name: "General Cards",
      //   count: 9,
      //   pro: true,
      // },
      // {
      //   image: `${imagesPrefix}/content-sections.jpg`,
      //   name: "Content Sections",
      //   count: 8,
      //   pro: true,
      // },
    ],
  },
  {
    title: "Mobile App Development",
    description: "Expert in gamification to attract long-term users.",
    items: [
      {
        image: vivablee1,
        name: "Vivablee",
        description: "Mindfulness App",
        route: "/project/vivablee",
      },
      {
        image: daydayfit1,
        name: "Day Day Fit",
        description: "Fitness Training App",
        route: "/project/daydayfit",
      },
      {
        image: moodfit1,
        name: "MoodFit",
        description: "Mental Wellbeing App",
        route: "/project/moodfit",
      },
    ],
  },
  // {
  //   title: "Input Areas",
  //   description: "50+ elements that you need for text manipulation and insertion",
  //   items: [
  //     {
  //       image: `${imagesPrefix}/newsletters.jpg`,
  //       name: "Newsletters",
  //       count: 6,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/contact-sections.jpg`,
  //       name: "Contact Sections",
  //       count: 8,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/forms.jpg`,
  //       name: "Forms",
  //       count: 3,
  //       route: "/sections/input-areas/forms",
  //     },
  //     {
  //       image: `${imagesPrefix}/inputs.jpg`,
  //       name: "Inputs",
  //       count: 6,
  //       route: "/sections/input-areas/inputs",
  //     },
  //   ],
  // },
  // {
  //   title: "Attention Catchers",
  //   description: "20+ Fully coded components that popup from different places of the screen",
  //   items: [
  //     {
  //       image: `${imagesPrefix}/alerts.jpg`,
  //       name: "Alerts",
  //       count: 4,
  //       route: "/sections/attention-catchers/alerts",
  //     },
  //     {
  //       image: `${imagesPrefix}/toasts.jpg`,
  //       name: "Notifications",
  //       count: 3,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/popovers.jpg`,
  //       name: "Tooltips & Popovers",
  //       count: 2,
  //       route: "/sections/attention-catchers/tooltips-popovers",
  //     },
  //     {
  //       image: `${imagesPrefix}/modals.jpg`,
  //       name: "Modals",
  //       count: 5,
  //       route: "/sections/attention-catchers/modals",
  //     },
  //   ],
  // },
  // {
  //   title: "Elements",
  //   description: "80+ carefully crafted small elements that come with multiple colors and shapes",
  //   items: [
  //     {
  //       image: `${imagesPrefix}/buttons.jpg`,
  //       name: "Buttons",
  //       count: 6,
  //       route: "/sections/elements/buttons",
  //     },
  //     {
  //       image: `${imagesPrefix}/avatars.jpg`,
  //       name: "Avatars",
  //       count: 2,
  //       route: "/sections/elements/avatars",
  //     },
  //     {
  //       image: `${imagesPrefix}/dropdowns.jpg`,
  //       name: "Dropdowns",
  //       count: 2,
  //       route: "/sections/elements/dropdowns",
  //     },
  //     {
  //       image: `${imagesPrefix}/switch.jpg`,
  //       name: "Toggles",
  //       count: 2,
  //       route: "/sections/elements/toggles",
  //     },
  //     {
  //       image: `${imagesPrefix}/social-buttons.jpg`,
  //       name: "Social Buttons",
  //       count: 2,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/breadcrumbs.jpg`,
  //       name: "Breadcrumbs",
  //       count: 1,
  //       route: "/sections/elements/breadcrumbs",
  //     },
  //     {
  //       image: `${imagesPrefix}/badges.jpg`,
  //       name: "Badges",
  //       count: 3,
  //       route: "/sections/elements/badges",
  //     },
  //     {
  //       image: `${imagesPrefix}/progress.jpg`,
  //       name: "Progress Bars",
  //       count: 4,
  //       route: "/sections/elements/progress-bars",
  //     },
  //     {
  //       image: `${imagesPrefix}/tables.jpg`,
  //       name: "Tables",
  //       count: 3,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/typography.jpg`,
  //       name: "Typography",
  //       count: 2,
  //       route: "/sections/elements/typography",
  //     },
  //   ],
  // },
];
