/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";
import MKBox from "components/MKBox";
import Grid from "@mui/material/Grid";
// import View from "layouts/sections/components/View";

import vivablee1 from "assets/images/projects/vivablee1.png";
// import storagehub2 from "assets/images/projects/storagehub2.png";
// import storagehub3 from "assets/images/projects/storagehub3.png";
// import storagehub4 from "assets/images/projects/storagehub4.png";
// import storagehub5 from "assets/images/projects/storagehub5.png";

// PageHeaders page components
// import HeaderOne from "layouts/sections/page-sections/page-headers/components/HeaderOne";

// PageHeaders page components code
// import headerOneCode from "layouts/sections/page-sections/page-headers/components/HeaderOne/code";

function PageHeaders() {
  return (
    <BaseLayout
      title="Vivablee"
      subTitle="Mindfulness App"
      breadcrumb={[
        { label: "Project" },
        { label: "Mobile App Development" },
        { label: "Vivablee" },
      ]}
    >
      {/* <View> */}
      <Grid marginTop={{ xs: -3, lg: -3 }} />
      <Grid container item xs={12} lg={9} justifyContent="center" mx="auto">
        <MKBox
          sx={{ mt: 6 }}
          component="img"
          src={vivablee1}
          borderRadius="lg"
          shadow="md"
          width="100%"
          position="relative"
          zIndex={1}
        />
      </Grid>
      {/* <Grid marginTop={{ xs: 3, lg: 6 }} />
      <Grid container item xs={12} lg={9} justifyContent="center" mx="auto">
        <MKBox
          component="img"
          src={storagehub2}
          borderRadius="lg"
          shadow="md"
          width="100%"
          position="relative"
          zIndex={1}
        />
      </Grid>
      <Grid marginTop={{ xs: 3, lg: 6 }} />
      <Grid container item xs={12} lg={9} justifyContent="center" mx="auto">
        <MKBox
          component="img"
          src={storagehub3}
          borderRadius="lg"
          shadow="md"
          width="100%"
          position="relative"
          zIndex={1}
        />
      </Grid>
      <Grid marginTop={{ xs: 3, lg: 6 }} />
      <Grid container item xs={12} lg={9} justifyContent="center" mx="auto">
        <MKBox
          component="img"
          src={storagehub4}
          borderRadius="lg"
          shadow="md"
          width="100%"
          position="relative"
          zIndex={1}
        />
      </Grid>
      <Grid marginTop={{ xs: 3, lg: 6 }} />
      <Grid container item xs={12} lg={9} justifyContent="center" mx="auto">
        <MKBox
          component="img"
          src={storagehub5}
          borderRadius="lg"
          shadow="md"
          width="100%"
          position="relative"
          zIndex={1}
        />
      </Grid> */}
      {/* </View> */}
    </BaseLayout>
  );
}

export default PageHeaders;
